import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "../../shared/services/login.service";
import { ApiEndpointConstant } from "../../core/api.endpoint.constant";
import { MemberService } from "../../shared/services/member.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TourService } from "src/app/shared/services/tour.services";
import { environment } from "src/environments/environment.prod";

const loginWithFacebookBaseUrl = ApiEndpointConstant.GetUrlLoginWithFacebook;
const loginWithLineBaseUrl = ApiEndpointConstant.GetUrlLoginWithLine;
const userProfileBaseUrl = ApiEndpointConstant.AuthenUserProfile;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  envName: string = environment.envName;
  version: string = ApiEndpointConstant.AppVersion;

  loginForm!: FormGroup;
  urlLoginWithFacebook: string = "";
  urlLoginWithLine: string = "";
  isLoginFacebook: any;
  isLoginLine: any;
  reference: string = "";
  isLoginFormNotValid: boolean = false;
  deviceInfo: any = null;
  pathImage: string = "";
  websiteName: string = "";
  welcomeMessage: string = "";
  private readonly _userKey: string = "appUser";
  private readonly _userInfoKey: string = "appUserInfo";
  accountLogin: any;
  usernameForLogin: any;
  passwordForLogin: any;
  isAutoLogin: boolean = false;
  marketingCode: string = "";
  affCode: string = "0";
  marketingChannelId: number = 0;
  private readonly _marketingChanelId: string = "appMarketingChanelId";
  private readonly _affCode: string = "appAffCode";
  logoLink: string = "";

  isLinkLine: any = null;
  isRegister: any = null;

  constructor(
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder,
    private urlRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _loginService: LoginService,
    private _httpClient: HttpClient,
    private _router: Router,
    private _memberService: MemberService,
    private _deviceService: DeviceDetectorService,
    private readonly _tourService: TourService
  ) {
    const navigation = this._router.getCurrentNavigation();

    const state = navigation?.extras.state as {
      username: string;
      password: string;
    };

    if (
      navigation?.extras.state != undefined &&
      navigation?.extras.state != null
    ) {
      this.usernameForLogin = state.username;
      this.passwordForLogin = state.password;
      if (this.usernameForLogin != "" && this.passwordForLogin != "") {
        this.isAutoLogin = true;
        this.onAutoLogin();
      } else {
        this.isAutoLogin = false;
      }
    } else {
      this.isAutoLogin = false;
    }

    //this.startTour();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.setImage();
    this.setName();
    this.setWelcomeMessage();
    this.setGamePlayLasted();
    this.getMarketingChannels();
    this.setLogoLink();

    setTimeout(() => {
      this.spinner.hide();
    }, 800);

    this.callGetClientStatus();

    this.urlLoginWithFacebook = loginWithFacebookBaseUrl;
    this.urlLoginWithLine = loginWithLineBaseUrl;

    this.isLoginFacebook = localStorage.getItem("isLoginFacebook");
    this.isLoginFacebook = JSON.parse(this.isLoginFacebook);

    this.isLoginLine = localStorage.getItem("isLoginLine");
    this.isLoginLine = JSON.parse(this.isLoginLine);

    this.isLinkLine = localStorage.getItem("linkLine");
    this.isLinkLine = JSON.parse(this.isLinkLine);

    this.isRegister = localStorage.getItem("registerLink");
    this.isRegister = JSON.parse(this.isRegister);

    setTimeout(() => {
      document.body.classList.remove("-open-menu-hamburger");
      document.body.style.overflow = "";
    }, 100);

    this.initLoginForm();

    this.urlRoute.queryParams.subscribe((params) => {
      if (params["code"] && params["Reference"]) {
        this.reference = params["Reference"];
        localStorage.setItem(
          "externalAuthReference",
          JSON.stringify(params["Reference"])
        );

        this.spinner.show();
        if (this.isLoginFacebook) {
          this._facebookInspectCode(params);
        } else if (this.isLoginLine) {
          this._lineInspectCode(params);
        }
      }
    });
    /* 
    this.urlRoute.params.subscribe((params: any) => {
      this.accountLogin = params['account'];
    }) 
 */

    this.onInitDataInLocalStorage();
  }

  callGetClientStatus() {
    this._loginService.getClientStatusBaseUrl().subscribe({
      next: (data) => {
        if (data == false) {
          window.location.href = "http://125.26.170.3/";
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  // reloadPage() {
  //   window.location.reload();
  // }

  // handleClick() {
  //   if (this.isRegister) {
  //     this.onRemoveLocalStorage();
  //   } else {
  //     this.reloadPage();
  //   }
  // }

  getMarketingChannels() {
    this._memberService.getMarketingChanels().subscribe({
      next: (data) => {
        this.setMarketingChannel(data);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setMarketingChannel(data: any[]) {
    //get marketing channel from url link
    this.urlRoute.queryParams.subscribe((params) => {
      if (params["channel"]) {
        this.marketingCode = params["channel"];
        let items = data.filter(
          (x) => x.code.toLowerCase() == this.marketingCode.toLowerCase()
        );
        if (items.length > 0) {
          this.marketingChannelId = items[0].id;
          localStorage.setItem(
            this._marketingChanelId,
            this.marketingChannelId.toString()
          );
        }
      }

      if (params["affiliatecode"]) {
        this.affCode = params["affiliatecode"];
        localStorage.setItem(this._affCode, this.affCode);
        // this.onSetAffliateCountFromAffliateCode(this.affCode);
      }
    });
  }

  onSetAffliateCountFromAffliateCode(
    registAffliateCode: string = this.affCode
  ) {
    let payload = {
      value: registAffliateCode,
    };

    this._memberService.setDataRegisterAffliateCount(payload).subscribe({
      next: (data) => {},
      error: (e) => {
        console.error(e);
      },
    });
  }

  initLoginForm() {
    this.loginForm = this._formBuilder.group({
      phoneForm: ["", Validators.required],
      passwordForm: ["", Validators.required],
    });
  }

  onClickBtnLogin() {
    this.spinner.show();
    this.isLoginFormNotValid = this.loginForm.invalid;
    if (this.isLoginFormNotValid) {
      this.spinner.hide();
      return;
    }

    let userName = this.loginForm.controls["phoneForm"].value;
    let password = this.loginForm.controls["passwordForm"].value;

    var body = new URLSearchParams();
    body.append("grant_type", "password");
    body.append("username", userName);
    body.append("password", password);
    body.append("client_id", "lsm-frontend");

    this.onInitDataInLocalStorage();

    this._loginService.postAuthExternalProvider(body).subscribe({
      next: (data) => {
        localStorage.setItem(this._userKey, data.access_token);
        this.getUserProfile$();
      },
      error: (e) => {
        console.error(e);
        let errorDescription = e.error.error_description;
        let errorMsg = `User: ${userName} has been locked 30 Minutes. (3 BadAttempts)`;
        if (errorDescription == errorMsg) {
          this.toastr.error("", "ยูเซอร์นี้ถูกล็อค กรุณาติดต่อแอดมินค่ะ");
        } else {
          this.toastr.error("", "เบอร์โทรหรือรหัสผ่านไม่ถูกต้อง");
        }
        this.spinner.hide();
      },
    });
  }

  onAutoLogin() {
    this.spinner.show();
    let userName = this.usernameForLogin;
    let password = this.passwordForLogin;

    var body = new URLSearchParams();
    body.append("grant_type", "password");
    body.append("username", userName);
    body.append("password", password);
    body.append("client_id", "lsm-frontend");

    this._loginService.postAuthExternalProvider(body).subscribe({
      next: (data) => {
        localStorage.setItem(this._userKey, data.access_token);
        //window.location.reload();
        this.getUserProfile$();
      },
      error: (e) => {
        console.error(e);
        let errorDescription = e.error.error_description;
        let errorMsg = `User: ${userName} has been locked 30 Minutes. (3 BadAttempts)`;
        if (errorDescription == errorMsg) {
          this.toastr.error("", "ยูเซอร์นี้ถูกล็อค กรุณาติดต่อแอดมินค่ะ");
        } else {
          this.toastr.error("", "เบอร์โทรหรือรหัสผ่านไม่ถูกต้อง");
        }
        this.spinner.hide();
      },
    });
  }

  getUserProfile$() {
    this.setDatatoLocalStorage();
    this._httpClient.get<any>(`${userProfileBaseUrl}`).subscribe({
      next: (data) => {
        if (data) {
          let obj = JSON.stringify(data);
          localStorage.setItem(this._userInfoKey, obj);
          this._router.navigateByUrl("/index").then(() => this.spinner.hide());
          window.location.reload();
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDatatoLocalStorage() {
    this._memberService.getBankList().subscribe({
      next: (data) => {
        localStorage.setItem("bankList", JSON.stringify(data));
      },
      error: (e) => {
        console.error(e);
      },
    });

    this._memberService.getSystemBankAccountTypeList().subscribe({
      next: (data) => {
        localStorage.setItem("systemBankAccountTypeList", JSON.stringify(data));
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onClickLoginFaceBook() {
    localStorage.setItem("isLoginFacebook", JSON.stringify(true));
    localStorage.setItem("isLoginLine", JSON.stringify(false));
  }

  onClickLoginLine() {
    localStorage.setItem("isLoginFacebook", JSON.stringify(false));
    localStorage.setItem("isLoginLine", JSON.stringify(true));
  }

  _facebookInspectCode(params: {}) {
    this._loginService.postFacebookInspectCode(params).subscribe({
      next: (data) => {
        let isNewUser: boolean;
        isNewUser = data.isNewUser;
        if (isNewUser) {
          this._router
            .navigateByUrl("/register")
            .then(() => this.spinner.hide());
        } else {
          // TODO not new user
          this.loginNewUserWith3rd(data);
          this.spinner.hide();
        }
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  _lineInspectCode(params: {}) {
    this._loginService.postLineInspectCode(params).subscribe({
      next: (data) => {
        let isNewUser: boolean;
        isNewUser = data.isNewUser;
        if (isNewUser) {
          this._router
            .navigateByUrl("/register")
            .then(() => this.spinner.hide());
        } else {
          // TODO not new user
          this.loginNewUserWith3rd(data);
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  loginNewUserWith3rd(data: any) {
    const body = new HttpParams()
      .set(
        "userId",
        this.isLoginLine ? data["userProfile"].userId : data["userProfile"].id
      )
      .set("reference", this.reference)
      .set("grant_type", "external_token_provider")
      .set("client_id", "lsm-frontend");

    this._loginService.postAuthExternalProvider(body).subscribe({
      next: (data) => {
        localStorage.setItem(this._userKey, data.access_token);
        this.getUserProfile$();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  onRemoveLocalStorage() {
    localStorage.removeItem("isLoginFacebook");
    localStorage.removeItem("isLoginLine");
    this.setGamePlayLasted();
  }

  onInitDataInLocalStorage() {
    localStorage.removeItem("deviceInfo");
    localStorage.setItem("deviceInfo", JSON.stringify(this._deviceService));
  }

  setGamePlayLasted() {
    localStorage.setItem("gameCasinoPlayLasted", "");
    localStorage.setItem("gameSlotPlayLasted", "");
    localStorage.setItem("gameSportPlayLasted", "");
    localStorage.setItem("gameLottoPlayLasted", "");
  }

  setWelcomeMessage() {
    let welcomeMessage = localStorage.getItem("welcomeMessage");
    if (welcomeMessage) {
      this.welcomeMessage = JSON.parse(
        localStorage.getItem("welcomeMessage") || ""
      );
    }
  }

  setImage() {
    // let webImage = localStorage.getItem('webImage');
    // if (webImage) {
    //   this.pathImage = JSON.parse(webImage || '');
    // }
    this.pathImage =
      "https://fs.cdnxn.com/lsm99king/king/img/logo/Default/logo.webp";
    // this.pathImage = 'https://fs.cdnxn.com/lsm99king/king/img/logo.gif';
  }

  setLogoLink() {
    let logoLink = localStorage.getItem("logoLink");
    if (logoLink) {
      this.logoLink = JSON.parse(logoLink || "");
    }
  }

  setName() {
    let websiteName = localStorage.getItem("websiteName");
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || "");
    }
  }

  startTour() {
    this._tourService.tourLoginFeature();
  }
}
