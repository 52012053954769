<div class="--desktop">
  <div class="w-[100%] my-4">
    <div class="group-games-icon-t">
      <a routerLink="/game/casinoListing">
        <div
          class="bg-box-icon"
          [ngClass]="{ active: this.path == 'casinoListing' }"
        >
          <div [ngClass]="{ active: this.path == 'casinoListing' }">
            <video
              class="casino-icon-active"
              *ngIf="this.path === 'casinoListing'"
              autoplay
              loop
              poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif"
            >
              <source
                type="video/webm"
                src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif"
              />
            </video>
            <div
              *ngIf="this.path !== 'casinoListing'"
              class="casino-icon"
            ></div>
          </div>
          <span
            class="text-at-"
            [ngClass]="{ active: this.path == 'casinoListing' }"
            >คาสิโน</span
          >
        </div>
      </a>

      <a routerLink="/game/gameListing">
        <div
          class="bg-box-icon"
          [ngClass]="{ active: this.path == 'gameListing' }"
        >
          <div [ngClass]="{ active: this.path == 'gameListing' }">
            <video
              class="slot-icon-active"
              *ngIf="this.path === 'gameListing'"
              autoplay
              loop
              poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif"
            >
              <source
                type="video/webm"
                src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif"
              />
            </video>
            <div *ngIf="this.path !== 'gameListing'" class="slot-icon"></div>
          </div>
          <span
            class="text-at-"
            [ngClass]="{ active: this.path == 'gameListing' }"
            >สล๊อต</span
          >
        </div>
      </a>

      <a routerLink="/game/sportListing">
        <div
          class="bg-box-icon"
          [ngClass]="{ active: this.path == 'sportListing' }"
        >
          <div [ngClass]="{ active: this.path == 'sportListing' }">
            <video
              class="sport-icon-active"
              *ngIf="this.path === 'sportListing'"
              autoplay
              loop
              poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif"
            >
              <source
                type="video/webm"
                src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif"
              />
            </video>
            <div *ngIf="this.path !== 'sportListing'" class="sport-icon"></div>
          </div>
          <span
            class="text-at-"
            [ngClass]="{ active: this.path == 'sportListing' }"
            >กีฬา</span
          >
        </div>
      </a>

      <a routerLink="/game/lottoListing">
        <div
          class="bg-box-icon"
          [ngClass]="{ active: this.path == 'lottoListing' }"
        >
          <div [ngClass]="{ active: this.path == 'lottoListing' }">
            <video
              class="lotto-icon-active"
              *ngIf="this.path === 'lottoListing'"
              autoplay
              loop
              poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif"
            >
              <source
                type="video/webm"
                src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif"
              />
            </video>
            <div *ngIf="this.path !== 'lottoListing'" class="lotto-icon"></div>
          </div>
          <span
            class="text-at-"
            [ngClass]="{ active: this.path == 'lottoListing' }"
            >หวย</span
          >
        </div>
      </a>
    </div>
  </div>
</div>

<!-- <div class="-mobile">
  <div class="w-[100%] my-4">
    <div class="group-games-icon-t">
    <a routerLink="/game/casinoListing">
      <div [ngClass]="this.path == 'casinoListing' ? 'active' : ''">
        <video class="casino-icon-active" *ngIf="this.path === 'casinoListing'" autoplay loop poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif">
          <source type="video/webm" src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m1.gif">
        </video>
        <div *ngIf="this.path !== 'casinoListing'" class="casino-icon"></div>
      </div>
    </a>
    
    <a routerLink="/game/gameListing" >
      <div [ngClass]="this.path == 'gameListing' ? 'active' : ''">
        <video class="slot-icon-active" *ngIf="this.path === 'gameListing'" autoplay loop poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif">
          <source type="video/webm" src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m2.gif">
        </video>
        <div *ngIf="this.path !== 'gameListing'" class="slot-icon"></div>
      </div>
    </a>
    
    <a routerLink="/game/sportListing">
      <div [ngClass]="this.path == 'sportListing' ? 'active' : ''">
        <video class="sport-icon-active" *ngIf="this.path === 'sportListing'" autoplay loop poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif">
          <source type="video/webm" src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m3.gif">
        </video>
        <div *ngIf="this.path !== 'sportListing'" class="sport-icon"></div>
      </div>
    </a>
    
    <a routerLink="/game/lottoListing">
      <div [ngClass]="this.path == 'lottoListing' ? 'active' : ''">
        <video class="lotto-icon-active" *ngIf="this.path === 'lottoListing'" autoplay loop poster="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif">
          <source type="video/webm" src="https://fs.cdnxn.com/lsm99king/king/img/category/Default/m4.gif">
        </video>
        <div *ngIf="this.path !== 'lottoListing'" class="lotto-icon"></div>
      </div>
    </a>
    </div>
  </div>
</div> -->
